import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import ComponentLayout from "./ComponentWrapper";

const HowToComponent = ({
  title,
  description,
  howToCards,
  duration,
  estimatedCost,
  estimatedCostUrrency,
  marginTop,
  marginBottom,
}) => {
  return (
    <ComponentLayout marginTop={marginTop} marginBottom={marginBottom}>
      <div>
        {title && (
          <div className="wp-block wp-block--heading">
            <h2>{title}</h2>
          </div>
        )}
        {description && (
          <div className="how-to--description">
            <img className="info-icon" src="/images/icons/info-icon.svg" alt="info-icon"></img>
            <div dangerouslySetInnerHTML={{ __html: description?.childMarkdownRemark?.html }} />{" "}
          </div>
        )}
        {duration && (
          <p className="rank-math-howto-duration">
            <strong>Kesto:</strong> <span>{duration}</span>
          </p>
        )}
        <div className="how-to-container">
          <div className="rank-math-steps ">
            {howToCards.length &&
              howToCards.map((howToCard) => {
                const image = getImage(howToCard?.image);
                return (
                  <div className="rank-math-step" key={howToCard?.labelText}>
                    <div className="step">
                      {howToCard?.labelText} {howToCard?.labelIndex}{" "}
                    </div>
                    <h4 className="rank-math-step-title ">{howToCard?.title}</h4>
                    <div className="rank-math-step-content ">
                      <GatsbyImage image={image} alt={howToCard?.title} />
                    </div>
                    <p>{howToCard?.description}</p>
                  </div>
                );
              })}
          </div>
        </div>
        {estimatedCost && (
          <p className="rank-math-howto-estimatedCost">
            <strong>Arvioitu kulu:</strong>{" "}
            <span>
              {estimatedCost} {estimatedCostUrrency}
            </span>
          </p>
        )}
      </div>
    </ComponentLayout>
  );
};

export default HowToComponent;
