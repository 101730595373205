import React from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useInView } from "react-intersection-observer";
import ComponentLayout from "./ComponentWrapper";

export const BlogCard = ({ slug, cardImage, title, categories, createdData }) => {
  const image = getImage(cardImage);

  let category;
  if (categories !== null || categories !== undefined) {
    category = categories[0]?.toLowerCase();
  } else category = "";

  return (
    <div className="latest-articles__item col-lg-3 col-sm-6">
      <article className="card article-card card--article post-12116 post type-post status-publish format-standard has-post-thumbnail hentry category-tarjoukset">
        <GatsbyImage
          image={image}
          alt={title}
          imgClassName="article-card__image img-fluid card-img-top w-100 d-block wp-post-image"
        />

        <div className="card-body">
          <header>
            <h3 className="entry-title card-title">
              <Link to={`/${category}/${slug}/`} class="stretched-link">
                {title}
              </Link>
            </h3>
          </header>
          <footer>
            <div className="article-card__meta entry-meta">
              <time className="updated" dateTime="2022-04-19T17:26:15+00:00">
                {createdData}
              </time>
              <div className="categories">Tarjoukset</div>
            </div>
          </footer>
        </div>
      </article>
    </div>
  );
};

const FeaturedPosts = ({ title, postCards, marginTop, marginBottom }) => {
  const [ref, inView] = useInView({
    rootMargin: "-100px",
    triggerOnce: true,
  });
  return (
    <ComponentLayout marginTop={marginTop} marginBottom={marginBottom}>
      <div className="casino-section frontpage-ctas" ref={ref}>
        {title && <h2 className="casino-section__title latest-articles__title">{title}</h2>}
        <div className="row casino-columns__list">
          {postCards.length &&
            postCards.map((cardItem) => {
              // createdData
              return (
                <BlogCard
                  key={cardItem?.title}
                  title={cardItem?.title}
                  slug={cardItem?.slug}
                  icon={cardItem?.icon}
                  cardImage={cardItem?.featuredImage}
                  categories={cardItem?.categories}
                  createdData={cardItem?.createdData}
                  inView={inView}
                />
              );
            })}
        </div>
      </div>
    </ComponentLayout>
  );
};

export default FeaturedPosts;
