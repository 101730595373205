export const ratingToDescription = (rating) => {
  let text;
  switch (rating) {
    case 10:
      text = "Häikäisevä";
      break;
    case 9.5:
      text = "Loistava";
      break;
    case 9:
      text = "Loistava";
      break;
    case 8.5:
      text = "Erittäin hyvä";
      break;
    case 8:
      text = "Erittäin hyvä";
      break;
    case 7.5:
      text = "Hyvä";
      break;
    case 7:
      text = "Hyvä";
      break;
    case 6.5:
      text = "Välttävä";
      break;
    case 6:
      text = "Välttävä";
      break;
    case 5.5:
      text = "Heikko";
      break;
    case 5:
      text = "Heikko";
      break;
    case 4.5:
      text = "Heikko";
      break;
    case 4:
      text = "Heikko";
      break;
    case 3.5:
      text = "Surkea";
      break;
    case 3:
      text = "Surkea";
      break;
    case 2.5:
      text = "Surkea";
      break;
    case 2:
      text = "Surkea";
      break;
    case 1.5:
      text = "Surkea";
      break;
    case 1:
      text = "Surkea";
      break;
    default:
      text = "";
  }

  return text;
};

export function slugify(str) {
  return String(str)
    .normalize("NFKD") // split accented characters into their base characters and diacritical marks
    .replace(/[\u0300-\u036f]/g, "") // remove all the accents, which happen to be all in the \u03xx UNICODE block.
    .trim() // trim leading or trailing whitespace
    .toLowerCase() // convert to lowercase
    .replace(/[^a-z0-9 -]/g, "") // remove non-alphanumeric characters
    .replace(/\s+/g, "-") // replace spaces with hyphens
    .replace(/-+/g, "-"); // remove consecutive hyphens
}
