import React from "react";
import styled from "styled-components";
import ComponentLayout from "./ComponentWrapper";

const ContentComponent = ({ data, marginTop, marginBottom }) => {
  return (
    <ComponentLayout marginTop={marginTop} marginBottom={marginBottom}>
      <ContentStyled
        className="wp-block wp-block--paragraph wp-block--float-image mt-4"
        dangerouslySetInnerHTML={{ __html: data }}
      />
    </ComponentLayout>
  );
};

const ContentStyled = styled.div`
  a {
    font-weight: 600;
  }
`;

export default ContentComponent;
