import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

import ComponentLayout from "./ComponentWrapper";

const GameOfTheMonth = ({ gameName, gameImage, gameDescription, playGameLink, link, marginTop, marginBottom }) => {
  const image = getImage(gameImage);

  const truncateText = (text, limit) => {
    return text && (text.length <= limit ? text : `${text.slice(0, limit)}...`);
  };

  const truncatedText = truncateText(gameDescription, 180);
  const splitList = playGameLink.slice(0, 3);

  return (
    <ComponentLayout marginTop={marginTop} marginBottom={marginBottom}>
      <div className="game-of-the-month-wrapper">
        <div className="game-of-the-month-content">
          <div>
            <h2 className="game-of-the-month-title">Kuukauden hittipeli</h2>
            <h3 className="game-of-the-month-game-name">{gameName}</h3>
            <div className="game-of-the-month-game-description" dangerouslySetInnerHTML={{ __html: truncatedText }} />
            <Link className="game-of-the-month-game-link" to={link}>
              Lue lisää
            </Link>
          </div>
          <div className="game-of-the-month-cta">
            <h2 className="game-of-the-month-title">Pelaa näillä kasinoilla:</h2>
            {splitList?.length &&
              splitList?.map((linkItem) => {
                return (
                  <a
                    target="_blank"
                    rel="noopener sponsored noreferrer"
                    className="game-of-the-month-game-link"
                    href={linkItem?.linkRoute}
                    key={linkItem?.linkText}
                  >
                    {linkItem?.linkText}
                  </a>
                );
              })}
          </div>
        </div>

        <div className="game-of-the-month-image">
          <GatsbyImage image={image} alt={gameName} imgStyle={{ zIndex: "1" }} />
        </div>
      </div>
    </ComponentLayout>
  );
};

export default GameOfTheMonth;
