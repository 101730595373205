import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import styled from "styled-components/macro";
import { Link } from "gatsby";
import { slugify } from "../utils/helpers";

const AuthorBox = ({ image, authorName, withTitle, backstory }) => {
  const authorImage = getImage(image);
  return (
    <AuthorContainer>
      {withTitle ? <h3>Kirjoittaja</h3> : null}
      <Author>
        <AuthorWrapper>
          <AuthorImage>
            <GatsbyImage image={authorImage} alt={authorName} />
          </AuthorImage>
          <InfoWrapper>
            {withTitle ? <Link to={`/kirjoittaja/${slugify(authorName)}`}>{authorName}</Link> : <h3>{authorName}</h3>}
            <p>Nettikasinolistan asiantuntija</p>
          </InfoWrapper>
        </AuthorWrapper>
        {backstory ? <div dangerouslySetInnerHTML={{ __html: backstory }} /> : null}
      </Author>
    </AuthorContainer>
  );
};

export default AuthorBox;

const AuthorContainer = styled.div`
  margin: 64px 0 32px;

  @media (max-width: 991.98px) {
    margin: 48px 0 0;
  }
`;

const Author = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 8px;
  background: #f6f8fb;
  padding: 16px;
`;

const AuthorWrapper = styled.div`
  margin: 24px 0;
  display: flex;
  width: 100%;
  gap: 24px;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  h3 {
    font-weight: 600;
    font-size: 36px;
    line-height: 1.2;
    margin: 8px 0 0;
    text-align: left;

    @media (max-width: 991.98px) {
      font-size: 24px;
    }
  }
  a {
    transition: all 0.2s ease-in-out;
    font-weight: 600;
    font-size: 24px;
    color: #444356;

    :hover {
      text-decoration: none;
      color: #5357ce;
    }
  }
`;

const AuthorImage = styled.div`
  width: 128px;
  height: 128px;
  border-radius: 100%;
  overflow: hidden;

  @media (max-width: 991.98px) {
    width: 96px;
    height: 96px;
  }
`;
