import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";

import ContentComponent from "../components/ContentComponent";
import HeroSection from "../components/HeroSection";
import Seo from "../components/Seo";
import { getFirstParagraphFromAllContentComponents, getFirstImageFromAllContentComponents } from "../utils/schemas";

import FaqComponent from "../components/FaqComponent";
import HowToComponent from "../components/HowToComponent";
import CasinosList from "../components/CasinosList";
import CtaComponent from "../components/CallToActionComponents";
import FeaturedPosts from "../components/FeaturedPosts";
import GameOfTheMonth from "../components/GameOfTheMonth";

import BonusList from "../components/BonusesList";
import VideoComponent from "../components/VideoComponent";
import AuthorBox from "../components/AuthorBox";

const slugWithoutImages = ["contact-us", "kayttoehdot", "tietosuojakaytanto", "cookies-evasteet"];

const WebsitePage = ({ data, location }) => {
  const pageData = data?.contentfulWebsitePage;

  const [faqItems, setFaqItems] = useState(undefined);
  const videoInfo = {
    videoTitle: "Katso Nettikasinolistan videoesittely!",
    videoDescription:
      "Jos haluat tietää enemmän nettikasinolista.com -sivustosta, katso esittelyvideo, jossa käydään läpi, mitä kaikkea Suomen parhaalta kasinosivustolta oikein löytyy!",
    iframeUrl: "https://www.youtube.com/embed/o9G2aZG-wHc",
  };
  const [howToSchema, setHowToSchema] = useState(undefined);
  const components = pageData?.components;
  const { author } = pageData;

  const firstParagraph = getFirstParagraphFromAllContentComponents(
    components,
    data?.contentfulWebsitePage?.pageName === "Nettikasinolista" ? "home" : null
  );
  let firstImage;
  if (slugWithoutImages.includes(pageData.slug)) {
    firstImage =
      "https:images.ctfassets.net/3g1jq8kgy3iv/2rm4QkFfE7VBfL8oahbIug/0a52b4758eaf7d6d6e6952e9012bf37e/nettikasino-lista.webp";
  } else {
    firstImage = getFirstImageFromAllContentComponents(components);
  }

  let techArticleSchema;
  if (pageData) {
    techArticleSchema = {
      title: data?.contentfulWebsitePage?.pageName,
      imageUrl: firstImage,
      description: firstParagraph,
      datePublished: pageData.createdAt,
      dateModified: pageData.updatedAt,
      slug: pageData.slug,
      author,
    };
  }
  useEffect(() => {
    components.forEach((element) => {
      if (element.__typename === "ContentfulFaqComponent") {
        setFaqItems(element.faqItems);
      } else if (element.__typename === "ContentfulHowTo") {
        setHowToSchema(element);
      }
    });
  }, [components]);
  return (
    <Layout location={location}>
      <div className="content container" style={{ minHeight: "700px" }}>
        {data?.contentfulWebsitePage?.pageName !== "Nettikasinolista" && (
          <div className="page-header">
            <h1 className="h1 header-pages">{data?.contentfulWebsitePage?.pageName}</h1>
          </div>
        )}
        {components?.map((component) => {
          switch (component.__typename) {
            case "ContentfulSeoComponent":
              return (
                <Seo
                  title={component?.seoTitle}
                  description={component?.seoDescription}
                  url={
                    component?.seoSlug === "/"
                      ? "https://nettikasinolista.com/"
                      : `https://nettikasinolista.com/${component?.seoSlug}/`
                  }
                  canonical={
                    component?.seoSlug === "/"
                      ? "https://nettikasinolista.com/"
                      : `https://nettikasinolista.com/${component?.seoSlug}/`
                  }
                  techArticleSchemaData={techArticleSchema}
                  fagSchemaItems={faqItems}
                  howToSchemaData={howToSchema}
                  videoSchemaData={data?.contentfulWebsitePage?.pageName === "Nettikasinolista" ? videoInfo : null}
                />
              );
            case "ContentfulHeroComponent":
              return (
                <HeroSection
                  title={component?.title}
                  casinosCardsData={component?.topCasinos}
                  backgroundColor={component?.backgroundColor}
                  pageName={data?.contentfulWebsitePage?.pageName}
                  marginTop={component?.marginTop}
                  marginBottom={component?.marginBottom}
                />
              );
            case "ContentfulRelatedBonuses":
              return (
                <BonusList
                  title={component?.title}
                  bonuses={component?.relatedBonusesList}
                  marginTop={component?.marginTop}
                  marginBottom={component?.marginBottom}
                />
              );
            case "ContentfulCallToAction":
              return (
                <CtaComponent
                  title={component?.title}
                  ctaCards={component?.callToActionCards}
                  marginTop={component?.marginTop}
                  marginBottom={component?.marginBottom}
                />
              );
            case "ContentfulGameOfTheMonth":
              return (
                <GameOfTheMonth
                  gameName={component?.gameName}
                  gameImage={component?.gameImage}
                  gameDescription={component?.gameDescription?.childMarkdownRemark?.html}
                  playGameLink={component?.playGameLink}
                  link={component?.link}
                  marginTop={component?.marginTop}
                  marginBottom={component?.marginBottom}
                />
              );
            case "ContentfulFeaturedPosts":
              return (
                <FeaturedPosts
                  title={component?.title}
                  postCards={component?.relatedPosts}
                  marginTop={component?.marginTop}
                  marginBottom={component?.marginBottom}
                />
              );
            case "ContentfulRelatedCasinosList":
              return (
                <CasinosList
                  title={component?.title}
                  casinosCardsData={component?.casinosList}
                  marginTop={component?.marginTop}
                  marginBottom={component?.marginBottom}
                />
              );
            case "ContentfulHowTo":
              return (
                <HowToComponent
                  title={component?.title}
                  description={component?.description}
                  duration={component?.duration}
                  estimatedCost={component?.estimatedCost}
                  estimatedCostUrrency={component?.estimatedCostUrrency}
                  howToCards={component?.howToCards}
                  marginTop={component?.marginTop}
                  marginBottom={component?.marginBottom}
                />
              );
            case "ContentfulContentComponent":
              return (
                <ContentComponent
                  data={component?.contentText?.childMarkdownRemark?.html}
                  marginTop={component?.marginTop}
                  marginBottom={component?.marginBottom}
                />
              );
            case "ContentfulFaqComponent":
              return (
                <FaqComponent
                  faqItems={component?.faqItems}
                  title={component?.title}
                  marginTop={component?.marginTop}
                  marginBottom={component?.marginBottom}
                />
              );

            default:
              return <></>;
          }
        })}
        {data?.contentfulWebsitePage?.pageName === "Nettikasinolista" ? <VideoComponent /> : null}
        {data?.contentfulWebsitePage?.author ? (
          <AuthorBox image={pageData.author.avatar} authorName={pageData.author.name} withTitle={true} />
        ) : null}
      </div>
    </Layout>
  );
};

export default WebsitePage;

export const query = graphql`
  query page($id: String!) {
    contentfulWebsitePage(id: { eq: $id }) {
      id
      pageName
      createdAt(formatString: "YYYY-MM-DD")
      updatedAt(formatString: "YYYY-MM-DD")
      slug
      author {
        avatar {
          url
          gatsbyImage(width: 120, quality: 100, placeholder: NONE, formats: [AUTO, WEBP])
          file {
            url
          }
        }
        name
      }
      components {
        ... on ContentfulSeoComponent {
          __typename
          seoSlug
          seoTitle
          seoDescription
        }
        ... on ContentfulCallToAction {
          __typename
          title
          marginBottom
          marginTop
          callToActionCards {
            title
            slug
            icon {
              file {
                url
              }
            }
          }
        }
        ... on ContentfulContentComponent {
          __typename
          marginBottom
          marginTop
          contentText {
            childMarkdownRemark {
              html
            }
          }
        }
        ... on ContentfulFaqComponent {
          __typename
          title
          marginBottom
          marginTop
          faqItems {
            faqAnswer {
              faqAnswer
              childMarkdownRemark {
                html
              }
            }
            faqQuestion
          }
        }
        ... on ContentfulRelatedBonuses {
          __typename
          title
          marginBottom
          marginTop
          relatedBonusesList {
            name
            slug
            description
            code
            referralUrl
            bonusBackgroundColor
            bonusLogo {
              gatsbyImage(width: 120, quality: 100, placeholder: NONE, formats: [AUTO, WEBP])
            }
          }
        }
        ... on ContentfulFeaturedPosts {
          __typename
          title
          marginBottom
          marginTop
          relatedPosts {
            title
            createdData(formatString: "DD.MM.YYYY")
            categories
            slug
            featuredImage {
              gatsbyImage(width: 400, quality: 70, placeholder: NONE, formats: [AUTO, WEBP])
            }
          }
        }
        ... on ContentfulGameOfTheMonth {
          __typename
          gameName
          link
          marginBottom
          marginTop
          gameImage {
            gatsbyImage(width: 880, quality: 70, placeholder: NONE, formats: [AUTO, WEBP])
          }
          gameDescription {
            childMarkdownRemark {
              html
            }
          }
          playGameLink {
            linkText
            linkType
            linkRoute
          }
        }
        ... on ContentfulHeroComponent {
          __typename
          title
          backgroundColor
          marginBottom
          marginTop
          topCasinos {
            casinoName
            shortDescription
            logoBackgroundColor
            slug
            categories
            referralUrl
            rating
            casinoAward
            awardTextColor
            logo {
              gatsbyImage(width: 120, quality: 70, placeholder: NONE, formats: [AUTO, WEBP])
            }
          }
        }
        ... on ContentfulHowTo {
          __typename
          title
          duration
          estimatedCost
          estimatedCostUrrency
          marginBottom
          marginTop
          description {
            childMarkdownRemark {
              html
            }
          }
          howToCards {
            title
            description
            labelText
            labelIndex
            image {
              gatsbyImage(width: 270, quality: 70, placeholder: NONE, formats: [AUTO, WEBP])
              file {
                url
              }
            }
          }
        }
        ... on ContentfulRelatedCasinosList {
          __typename
          title
          marginBottom
          marginTop
          casinosList {
            casinoName
            shortDescription
            logoBackgroundColor
            slug
            categories
            referralUrl
            rating
            logo {
              gatsbyImage(width: 120, quality: 70, placeholder: NONE, formats: [AUTO, WEBP])
            }
          }
        }
      }
    }
  }
`;
