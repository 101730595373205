import React from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ComponentLayout from "./ComponentWrapper";

const CasinoCard = ({
  casinoName,
  logo,
  backgroundColor,
  description,
  slug,
  categories,
  referralUrl,
  casinoAward,
  awardTextColor,
  rating,
}) => {
  const image = getImage(logo);
  const category = categories?.toLowerCase();
  return (
    <div className="col-lg-3">
      <div className="card card-award card--award card-award--monthly">
        <div className="card-body card-award__content">
          <span style={{ color: awardTextColor }} className="card-award--description">
            {casinoAward}
          </span>
          <Link to={`/${category}/${slug}/`} class="casino-card__link">
            <GatsbyImage image={image} alt={casinoName} imgStyle={{ zIndex: "1" }} />
            <div className="top-casino-card__bg" style={{ backgroundColor }}></div>
          </Link>
        </div>
        <div className="casino-card__content card-body">
          <div className="top-casino-card__title">
            <Link to={`/${category}/${slug}/`} class="casino-card__link">
              {casinoName}
            </Link>
          </div>
          <div className="top-casino-card__description">{description}</div>
          <div className="top-casino-card-footer">
            <div className="top-casino-rating">
              <p>&#9733;</p>
              <span>{rating}/10</span>
            </div>
            <a
              href={referralUrl}
              className="btn btn--play-now btn-primary btn-sm btn-block card-link"
              target="_blank"
              rel="noopener sponsored noreferrer"
              data-list="DZADARATBEBOBABRCACLCOCZECEGFIDEHUISINIEJPKZLILVLTLUMTMXMCMANLNZNOPYPEQARSSKSICHAEUYUZ"
            >
              Pelaa nyt
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const HeroSection = ({ title, casinosCardsData, backgroundColor, marginTop, marginBottom }) => {
  return (
    <ComponentLayout marginTop={marginTop} marginBottom={marginBottom}>
      <div className="frontpage-jumbotron carousel section section--full-width" style={{ backgroundColor }}>
        <div className="frontpage-jumbotron__container">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">{title && <h1 className="hero-casinos__title">{title}</h1>}</div>

              <div className="outer">
                <div className="cards">
                  <div className="inner inner-hero-section">
                    {casinosCardsData && casinosCardsData.length
                      ? casinosCardsData.map((casinoItem) => {
                          return (
                            <CasinoCard
                              key={casinoItem?.casinoName}
                              casinoName={casinoItem?.casinoName}
                              description={casinoItem?.shortDescription}
                              referralUrl={casinoItem?.referralUrl}
                              backgroundColor={casinoItem?.logoBackgroundColor}
                              logo={casinoItem?.logo}
                              slug={casinoItem?.slug}
                              categories={casinoItem?.categories}
                              casinoAward={casinoItem?.casinoAward}
                              awardTextColor={casinoItem?.awardTextColor}
                              rating={casinoItem?.rating}
                            />
                          );
                        })
                      : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ComponentLayout>
  );
};

export default HeroSection;
