import React from "react";
import { useInView } from "react-intersection-observer";
import BonusCard from "./BonusCard";
import ComponentLayout from "./ComponentWrapper";

const BonusList = ({ title, bonuses, marginTop, marginBottom }) => {
  const [ref, inView] = useInView({
    rootMargin: "-100px",
    triggerOnce: true,
  });
  return (
    <ComponentLayout marginTop={marginTop} marginBottom={marginBottom}>
      <section className="casino-section" ref={ref}>
        <div className="newest-bonuses casino-bonuses casino-columns">
          {title && <h2 className="newest-bonuses__title h4">{title}</h2>}
          <div className="casino-bonuses__list casino-columns__list row">
            {bonuses?.length &&
              bonuses?.map((bonus) => {
                return (
                  <BonusCard
                    key={bonus?.code}
                    name={bonus?.code}
                    logo={bonus?.bonusLogo}
                    bonusBackgroundColor={bonus?.bonusBackgroundColor}
                    description={bonus?.description}
                    referralUrl={bonus?.referralUrl}
                    partnersTermsAndConditionalLink={bonus?.partnersTermsAndConditionalLink}
                    inView={inView}
                    className="casino-bonuses__item col-6 col-sm-6 col-lg-4 col-xl-2"
                  />
                );
              })}
          </div>
        </div>
      </section>
    </ComponentLayout>
  );
};

export default BonusList;
