import React, { useState } from "react";
import { useInView } from "react-intersection-observer";
import ComponentLayout from "./ComponentWrapper";

const FaqItem = ({ faqTitle, faqBody }) => {
  const [accordionOpen, setAccordionOpen] = useState(false);

  const [ref, inView] = useInView({
    rootMargin: "-100px",
  });
  return (
    <div className="faq-item" ref={ref}>
      <div className={`faq-title ${accordionOpen ? "faq-open-title" : ""}`}>
        <h2>{faqTitle}</h2>
        <button
          onClick={() => setAccordionOpen(!accordionOpen)}
          className="faq-item-button"
          aria-label={`faq-header-${faqTitle}`}
        >
          {inView && <img src="/images/icons/arrow-right.svg" alt="faq-item-button" />}
        </button>
      </div>
      <div className={`faq-body ${accordionOpen ? "faq-open" : ""}`}>
        <div className="card-text" dangerouslySetInnerHTML={{ __html: faqBody?.childMarkdownRemark?.html }} />
      </div>
    </div>
  );
};

const FaqComponent = ({ faqItems, title, marginTop, marginBottom }) => {
  return (
    <ComponentLayout marginTop={marginTop} marginBottom={marginBottom}>
      <div>
        <div className="wp-block wp-block--heading">
          <h2 className="mt-5">{title}</h2>
        </div>
        <div className="wp-block wp-block--rank-mathfaq-block">
          {faqItems
            ? faqItems.map((faqItem) => {
                return (
                  <FaqItem
                    className="rank-math-list-item"
                    faqTitle={faqItem?.faqQuestion}
                    faqBody={faqItem?.faqAnswer}
                    key={faqItem?.faqQuestion}
                  />
                );
              })
            : null}
        </div>
      </div>
    </ComponentLayout>
  );
};

export default FaqComponent;
