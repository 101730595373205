import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";

const BonusCard = ({
  name,
  logo,
  description,
  referralUrl,
  bonusBackgroundColor,
  className,
  partnersTermsAndConditionalLink,
}) => {
  const image = getImage(logo);

  return (
    <div className={className}>
      <div className="casino-bonus casino-card card ">
        <div className="casino-bonus__inner">
          <div className="casino-bonus__code">
            <span>{name}</span>
          </div>
          <div className="casino-card__image casino-bonus__image card-img">
            <GatsbyImage
              image={image}
              alt={name}
              imgStyle={{ zIndex: "1", maxWidth: "120px" }}
              imgClassName="casino-card__logo casino-bonus__logo wp-post-image"
            />
            <div className="casino-card__bg" style={{ backgroundColor: bonusBackgroundColor }}></div>
          </div>

          <div className="casino-card__content card-body">
            <div className="casino-card__text">
              <p>{description}</p>
            </div>
            <a
              href={referralUrl}
              className="btn btn--play-now btn-primary btn-sm btn-block card-link stretched-link"
              target="_blank"
              rel="noopener sponsored noreferrer"
              data-list="DZADARATBEBOBABRCACLCOCZECEGFIDEHUISINIEJPKZLILVLTLUMTMXMCMANLNZNOPYPEQARSSKSICHAEUYUZ"
            >
              Nappaa bonus
            </a>
            {partnersTermsAndConditionalLink && (
              <a className="terms-bonus" href={partnersTermsAndConditionalLink}>
                Säännöt &amp; Ehdot
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

BonusCard.defaultProps = {
  className: `casino-bonuses__item casino-bonuses__item col-6 col-lg-1-5`,
};

BonusCard.propTypes = {
  className: PropTypes.string,
};

export default BonusCard;
