import React from "react";
import { Link } from "gatsby";

import ComponentLayout from "./ComponentWrapper";

const CtaCard = ({ slug, icon, title }) => {
  return (
    <div className="col-lg col-md-6 col-12">
      <div className="cta-box">
        <div className="cta-box__header">
          <span className="cta-box__icon">
            <img src={icon?.file?.url} alt={title} width="48" height="48" />
          </span>
          <p className="cta-box__title">{title}</p>
        </div>
        <div className="cta-box__footer">
          <Link to={slug} class="cta-box__link stretched-link">
            Lue lisää
          </Link>
        </div>
      </div>
    </div>
  );
};

const CtaComponent = ({ title, ctaCards, marginTop, marginBottom }) => {
  return (
    <ComponentLayout marginTop={marginTop} marginBottom={marginBottom}>
      <div className="casino-section frontpage-ctas">
        {title && <h2 className="frontpage-ctas__title">{title}</h2>}
        <div className="row casino-columns__list">
          {ctaCards.length &&
            ctaCards.map((cardItem) => {
              return (
                <CtaCard key={cardItem?.title} title={cardItem?.title} slug={cardItem?.slug} icon={cardItem?.icon} />
              );
            })}
        </div>
      </div>
    </ComponentLayout>
  );
};

export default CtaComponent;
