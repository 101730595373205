import React from "react";
import styled from "styled-components";
import { useInView } from "react-intersection-observer";
import ComponentLayout from "./ComponentWrapper";

const VideoComponent = () => {
  const [ref, inView] = useInView({
    rootMargin: "-100px",
    triggerOnce: true,
  });
  return (
    <ComponentLayout marginTop={0} marginBottom={0}>
      <WrapperCoponent ref={ref}>
        <DescriptionWrapper>
          <h4>Katso Nettikasinolistan videoesittely!</h4>
          <div>
            Jos haluat tietää enemmän nettikasinolista.com -sivustosta, katso esittelyvideo, jossa käydään läpi, mitä
            kaikkea Suomen parhaalta kasinosivustolta oikein löytyy!
          </div>
        </DescriptionWrapper>
        <WrapperIframe>
          <ContainerIframe>
            {inView && (
              <StyledIframe
                className="responsive-iframe"
                src="https://www.youtube.com/embed/o9G2aZG-wHc"
              ></StyledIframe>
            )}
          </ContainerIframe>
        </WrapperIframe>
      </WrapperCoponent>
    </ComponentLayout>
  );
};

export default VideoComponent;

const WrapperCoponent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 64px;

  @media (max-width: 640px) {
    margin-top: 48px;
    flex-direction: column;
  }
`;

const DescriptionWrapper = styled.div`
  width: 100%;
  max-width: 572px;
`;

const ContainerIframe = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
  top: 50%;
  .responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
`;

const StyledIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none !important;
`;

const WrapperIframe = styled.div`
  width: 100%;
  height: 100%;
  max-width: 636px;
  max-height: 316px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
